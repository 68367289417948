import { css } from "@emotion/core"
import React from "react"
import Layout from "../components/layout"

export default function Page404() {
  return (
    <Layout>
      <section
        className="wrapper"
        css={css`
          padding-top: 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 3rem;
          font-weight: 700;
          color: var(--primary-default);
          p {
            font-size: 2rem;
            font-weight: 300;
            margin-top: 0px;
          }
        `}
      >
        404
        <p>Nie ma takiej strony!</p>
      </section>
    </Layout>
  )
}
